import { render, staticRenderFns } from "./batchGather.vue?vue&type=template&id=cfeb3e4a&scoped=true&"
import script from "./batchGather.vue?vue&type=script&lang=js&"
export * from "./batchGather.vue?vue&type=script&lang=js&"
import style0 from "./batchGather.vue?vue&type=style&index=0&id=cfeb3e4a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfeb3e4a",
  null
  
)

export default component.exports